var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-interface',{scopedSlots:_vm._u([{key:"interface-toolbar",fn:function(){return [_c('month-picker',{model:{value:(_vm.currentDateRange),callback:function ($$v) {_vm.currentDateRange=$$v},expression:"currentDateRange"}})]},proxy:true},{key:"interface-heading",fn:function(){return [_vm._v(" Billing ")]},proxy:true}])},[_c('styled-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Billing by Product ")]},proxy:true},{key:"action-buttons",fn:function(){return [_c('action-button',{attrs:{"icon":"download"},on:{"click":_vm.exportCSV}},[_vm._v(" Export CSV ")])]},proxy:true}])},[_c('div',{staticClass:"groups-picker"},[_c('div',{staticClass:"groups-picker__holder"},[_c('groups-picker-global',{attrs:{"disabled":_vm.loading}})],1)]),(_vm.loading || _vm.groupsLoading)?_c('div',{staticClass:"text-xs-center pa-5"},[_c('loader',{attrs:{"label":"Loading Billing Data"}})],1):[_c('div',{staticClass:"layout px-4 py-4 justify-space-around row align-end mb-4 wrap"},[_c('div',{class:{
                        'stats-element': true,
                        'active': (_vm.activeFilter === null)
                    }},[_c('div',[_c('span',{staticClass:"text-uppercase"},[_vm._v(" TOTAL PRODUCTS ")]),_c('h4',{staticClass:"font-weight-medium-bold light-blue--text"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.filterByProduct(null)}}},[_vm._v(" "+_vm._s(_vm.dealerProducts.length)+" ")])])])]),_vm._l((_vm.products),function(product,index){return _c('div',{key:index,class:{
                        'stats-element': true,
                        'active': (_vm.activeFilter === product.product_id)
                    }},[_c('div',[_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(product.product)+" ")]),_c('h4',{staticClass:"font-weight-medium-bold text-uppercase"},[_c('a',{staticClass:"text--green",on:{"click":function($event){$event.preventDefault();return _vm.filterByProduct(product.product_id)}}},[_vm._v(" "+_vm._s(product.count)+" ")])])])])})],2),_c('styled-table',{attrs:{"headers":_vm.headers,"items":_vm.dealerProductsDisplayed,"loading":_vm.loading,"pagination":_vm.pagination,"min-width":"1200px","hide-header":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function({ item, index }){return [_c('tr',{class:[
                            index % 2 ? 'row-even' : 'row-odd'
                        ]},[_c('td',{staticClass:"pa-4"},[_c('router-link',{attrs:{"to":{ name: 'dealer', params: { dealer_id: item.id }}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('td',{staticClass:"pa-4"},[_c('router-link',{attrs:{"to":{ name: 'dealer', params: { dealer_id: item.id }}}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('td',{staticClass:"pa-4 ellipsis"},[_c('span',[_c('a',{attrs:{"target":"_blank","href":item.site_base_url}},[_vm._v(" "+_vm._s(_vm.getDomain(item.site_base_url))+" ")])])]),_c('td',{staticClass:"pa-4 text-uppercase ellipsis"},[_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.external_id),expression:"item.external_id",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],attrs:{"title":item.external_id}},[_vm._v(" "+_vm._s(item.external_id)+" ")])]),_c('td',{staticClass:"pa-4 text-uppercase"},[_vm._v(" "+_vm._s(item.product)+" ")]),_c('td',{staticClass:"pa-4"},[(item.product_started)?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("localizeTimezone")(item.product_started.date),'lll'))+" ")]:_vm._e()],2),_c('td',{staticClass:"pa-4"},[(item.product_ended)?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("localizeTimezone")(item.product_ended.date),'lll'))+" ")]:_vm._e()],2)])]}}])},[_c('template',{slot:"no-data"},[(!_vm.loading)?_c('div',{staticClass:"text-xs-center pa-5"},[_c('h4',[_vm._v("No results found")])]):_vm._e()])],2)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }